import React from 'react';
import './ContactSection.css';
import { FaGithub, FaLinkedin } from 'react-icons/fa';
import { FaXTwitter } from "react-icons/fa6";

const ContactSection: React.FC = () => {
    return (
        <section id="contact" className="contact-section">
            <div className="container">
                <h2>Let's Chat</h2>
                <p className="subheading">
                    Need an innovation expert or experienced senior full-stack developer to help out? {' '}
                    <a href="mailto:ondrej.sarnecky@0x1.sk" className="email-link">
                        Send me an email to: ondrej.sarnecky@0x1.sk
                    </a> {' '}
                    to discuss your project.
                </p>
                <p className="alternative">
                    Alternatively connect with me elsewhere on the web →
                </p>
                <div className="social-links">
                    <a href="https://github.com/OndroS" target="_blank" rel="noopener noreferrer">
                        <FaGithub />
                    </a>
                    <a href="https://www.linkedin.com/in/ondrej-sarnecky/" target="_blank" rel="noopener noreferrer">
                        <FaLinkedin />
                    </a>
                    <a href="https://twitter.com/ondrej_sarnecky" target="_blank" rel="noopener noreferrer">
                        <FaXTwitter />
                    </a>
                </div>
            </div>
        </section>
    );
};

export default ContactSection;