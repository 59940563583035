import React, { useState, useEffect } from 'react';
import './PhotoGallery.css';

// Gallery images
import dubai1 from '../../assets/gallery/dubai1.jpeg';
import fintech1 from '../../assets/gallery/fintech1.jpg';
import metro2 from '../../assets/gallery/metro2.jpg';
// import dubai2 from '../../assets/gallery/dubai2.jpeg';
import bsk1 from '../../assets/gallery/bsk1.jpg';
import sp1 from '../../assets/gallery/sp1.jpg';
import cambridge1 from '../../assets/gallery/cambridge1.jpg';
import cambridge2 from '../../assets/gallery/cambridge2.png';


interface SpeakingEvent {
    image: string;
    title: string;
    date?: string;
    location: string;
}

const events: SpeakingEvent[] = [
    {
        image: dubai1,
        title: "DXB Innovation Study",
        location: "EY HQ, Dubai, UAE"
    },
    {
        image: cambridge1,
        title: "Carbon 13 - Cohort 5",
        location: "University of Cambridge, UK"
    },
    {
        image: fintech1,
        title: "Case study: Blockchain and the Drive Towards a Cashless Society",
        location: "Fin.Techsummit 2018, Bratislava, Slovakia"
    },
    {
        image: cambridge2,
        title: "Carbon 13 - Cohort 5",
        location: "University of Cambridge, UK"
    },
    {
        image: metro2,
        title: "Blockchain & AI - Can they work together?",
        location: "Metro Online, Poprad, Slovakia"
    },
    // {
    //     image: dubai2,
    //     title: "DXB Innovation Study",
    //     location: "EY HQ, Dubai, UAE"
    // },
    {
        image: bsk1,
        title: "Blockchain & AI - Can they work together?",
        location: "HubHub, Bratislava, Slovakia"
    },
    {
        image: sp1,
        title: "Intro to Blockchain Technology",
        location: "Innoformum, Bratislava, Slovakia"
    },
];

const PhotoGallery: React.FC = () => {
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        // const interval = setInterval(() => {
        //     setActiveIndex((prevIndex) => (prevIndex + 1) % events.length);
        // }, 15000); // Change image every 15 seconds

        // return () => clearInterval(interval);
    }, []);

    return (
        <section className="speaking-engagements-section">
            <div className="container mx-auto px-6 py-32">
                <h2 className="text-4xl md:text-5xl font-bold text-white text-center mb-2">See me in action</h2>
                <p className="text-xl text-gray-400 text-center mb-16">Ocasional speaker that sell</p>
                <div className="speech-showcase">
                    <div className="speech-images">
                        {events.map((event, index) => (
                            <div 
                                key={index} 
                                className={`speech-image ${index === activeIndex ? 'active' : ''}`}
                                style={{ backgroundImage: `url(${event.image})` }}
                            />
                        ))}
                    </div>
                    <div className="speech-info">
                        <h3 className="text-2xl font-semibold text-white mb-2">{events[activeIndex].title}</h3>
                        {/* <p className="text-gray-300 mb-2">{events[activeIndex].date}</p> */}
                        <p className="text-gray-300">{events[activeIndex].location}</p>
                    </div>
                </div>
                <div className="speech-thumbnails">
                    {events.map((event, index) => (
                        <div 
                            key={index} 
                            className={`speech-thumbnail ${index === activeIndex ? 'active' : ''}`}
                            style={{ backgroundImage: `url(${event.image})` }}
                            onClick={() => setActiveIndex(index)}
                        />
                    ))}
                </div>
            </div>
        </section>
    );
};

export default PhotoGallery;