import React from 'react';
import './AboutMe.css';

const AboutMe: React.FC = () => {
    const expertiseAreas = [
        {
            title: "Innovation Expert",
            description: "Driving cutting-edge solutions and fostering a culture of creative problem-solving in technology and business.",
            icon: "💡", // You can replace this with an actual icon component or SVG
            areaTitle: "Areas of focus:",
            skills: [
                'Innovation Strategy',
                'Product Development',
                'Digital Transformation',
                'R&D'
            ]
        },
        {
            title: "Software Engineer",
            description: "Crafting robust, scalable applications with a focus on clean code and optimal performance across various platforms.",
            icon: "💻", // You can replace this with an actual icon component or SVG
            areaTitle: "Tech I speak:",
            skills: [
                'Web Development',
                'AI Development',
                'Blockchain Development',
                'API Integration'
            ]
        },
        {
            title: "Consultant",
            description: "Providing strategic insights and technical expertise to help businesses leverage technology for growth and efficiency.",
            icon: "📊", // You can replace this with an actual icon component or SVG
            areaTitle: "I'll help you with:",
            skills: [
                'Innovation Lead',
                'Technical Consulting',
                'Business Analysis',
                'Project Management'
            ]
        }
    ];

    return (
        <section id="about" className="about-me-section">
            <div className="container mx-auto px-6 py-32">
                <h2 className="text-4xl md:text-5xl font-bold text-white text-center mb-2">I am</h2>
                <p className="text-xl text-gray-400 text-center mb-16">Bringing ideas to life through technology</p>
                <div className="expertise-grid">
                    {expertiseAreas.map((area, index) => (
                        <div key={index} className="expertise-card">
                            <div className="expertise-icon">{area.icon}</div>
                            <h3 className="text-2xl font-semibold text-white mb-4">{area.title}</h3>
                            <p className="text-gray-300 mb-4">{area.description}</p>
                            <h4 className="text-lg font-semibold text-white mt-4">{area.areaTitle}</h4>
                            <ul className="mt-2">
                                {area.skills.map((skill, i) => (
                                    <li key={i} className="text-gray-300">{skill}</li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default AboutMe;