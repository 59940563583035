'use client'

import React, { useState, useEffect } from 'react'
import { Dialog } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import './Header.css'

interface NavigationItem {
    name: string;
    href: string;
}

const navigation: NavigationItem[] = [
    { name: 'Experience', href: '/#experience' },
    { name: 'I am...', href: '/#about' },
    { name: 'My Work', href: '/#work' },
    { name: 'Case Studies', href: '/coming-soon' },
    { name: 'Blog', href: 'https://medium.com/@ondrejsarnecky' },
    { name: 'Say Hello', href: '/#contact' },
]

const Header: React.FC = () => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
    const [language, setLanguage] = useState('EN')
    const [isSticky, setIsSticky] = useState(false)

    const toggleLanguage = () => {
        setLanguage(language === 'EN' ? 'DE' : 'EN')
    }

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setIsSticky(true)
            } else {
                setIsSticky(false)
            }
        }

        window.addEventListener('scroll', handleScroll)

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    const handleMobileMenuItemClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault()
        const href = event.currentTarget.getAttribute('href')
        setMobileMenuOpen(false)
        setTimeout(() => {
            if (href) {
                window.location.href = href
            }
        }, 300) // Delay to allow menu closing animation
    }

    return (
        <header className={`${isSticky ? 'fixed' : 'absolute'} inset-x-0 top-0 z-50 transition-all duration-300`}>
            <nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
                {/* Logo */}
                <div className="flex lg:flex-1">
                    <a href="#" className="-m-1.5 p-1.5">
                        <span className="sr-only">SecretSauce.Digital</span>
                        <span className="text-[1.6rem] font-bold text-white">SecretSauce.Digital</span>
                    </a>
                </div>
                
                {/* Mobile menu button */}
                <div className="flex lg:hidden">
                    <button
                        type="button"
                        className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-white"
                        onClick={() => setMobileMenuOpen(true)}
                    >
                        <span className="sr-only">Open main menu</span>
                        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
                
                {/* Desktop navigation */}
                <div className="hidden lg:flex lg:gap-x-12">
                    {navigation.map((item) => (
                        <a key={item.name} href={item.href} className="text-m leading-6 text-white menu-item" data-text={item.name}>
                            <span className="menu-item-text">{item.name}</span>
                        </a>
                    ))}
                    <button onClick={toggleLanguage} className="text-sm font-light leading-6 text-white menu-item" data-text={language === 'EN' ? 'EN | DE' : 'DE | EN'}>
                        <span className="menu-item-text">{language === 'EN' ? 'EN | DE' : 'DE | EN'}</span>
                    </button>
                </div>
            </nav>

            {/* Mobile menu */}
            <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={() => setMobileMenuOpen(false)}>
                <div className="fixed inset-0 z-50" />
                <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10 transition-transform duration-300 ease-in-out">
                    <div className="flex items-center justify-between">
                        <a href="#" className="-m-1.5 p-1.5">
                            <span className="sr-only">Ondrej Sarnecky</span>
                            <span className="text-xl font-bold text-gray-900">Ondrej Sarnecky</span>
                        </a>
                        <button
                            type="button"
                            className="-m-2.5 rounded-md p-2.5 text-gray-700"
                            onClick={() => setMobileMenuOpen(false)}
                        >
                            <span className="sr-only">Close menu</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>
                    <div className="mt-6 flow-root">
                        <div className="-my-6 divide-y divide-gray-500/10">
                            <div className="space-y-2 py-6">
                                {navigation.map((item) => (
                                    <a
                                        key={item.name}
                                        href={item.href}
                                        className="-mx-3 block rounded-lg px-3 py-2 text-base font-light leading-7 text-gray-900"
                                        onClick={handleMobileMenuItemClick}
                                    >
                                        {item.name}
                                    </a>
                                ))}
                                <button
                                    onClick={toggleLanguage}
                                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-light leading-7 text-gray-900"
                                >
                                    {language === 'EN' ? 'EN | DE' : 'DE | EN'}
                                </button>
                            </div>
                        </div>
                    </div>
                </Dialog.Panel>
            </Dialog>
        </header>
    )
}

export default Header