'use client'
import React from 'react';

export default function Bio() {
    return (
        <section className="relative bg-black text-white min-h-screen flex flex-col justify-center items-center px-4 sm:px-6 lg:px-8">
            <div className="max-w-7xl mx-auto">
                <p className="text-sm mb-8">EXPERTISE MEETS INNOVATION</p>
                <h2 className="text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-thin leading-tight mb-8">
                    I'm Ondrej, an innovation expert from Slovakia, building apps, products and online experiences for companies large and small. <span className='font-bold'>I'm helping companies grow in their revenue by making them f* cool.</span>
                </h2>
                <div className="flex flex-row sm:flex-col md:flex-row justify-between sm:justify-center sm:gap-8 md:gap-32 mt-16">
                    <a href="#experience" className="w-[48%] sm:w-auto">
                        <button className="w-full sm:w-auto px-4 sm:px-8 py-3 border-2 hover:bg-white text-white hover:text-black font-semibold full hover:bg-opacity-90 transition duration-300">
                            Know more
                        </button>
                    </a>
                    <a href="#contact" className="w-[48%] sm:w-auto">
                        <button className="w-full sm:w-auto px-4 sm:px-8 py-3 border-2 hover:bg-white text-white hover:text-black font-semibold full hover:bg-opacity-90 transition duration-300">
                            Contact
                        </button>
                    </a>
                </div>
            </div>
        </section>
    )
}